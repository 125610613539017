
import { defineComponent, ref, reactive } from "vue";
import VueJsonPretty from 'vue-json-pretty';
import { translate } from "@/core/helpers/translate";
import 'vue-json-pretty/lib/styles.css';
import { useRoute } from "vue-router";
import {
  showErrorPopup,
  showSuccessPopup,
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import useInvocationDetail from "@/core/services/compositions/invocation/useInvocationDetail";
import { checkPermission } from '@/router/authentication';

interface Resend {
  invocation_id: string;
}

export default defineComponent({
  components: {
    VueJsonPretty
  },
  setup() {
    const route = useRoute();
    const dialogVisible = ref(false)
    const submitButton = ref<null | HTMLButtonElement>(null);

    const invocationId = route.params.id;

    const { data, mutate: refreshList } = useInvocationDetail(invocationId);

    const invocation = reactive<Resend>({
      invocation_id: '',
    });

    const resend = (id) => {
      dialogVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      ApiService.setHeader();
      ApiService.post(
        `app/webhook/${id}/resend`,
        {}
      )
        .then(() => {
          showSuccessPopup({
            title: 'Resend Webhook',
            text: 'Success'
          });

          refreshList();

        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            for (const key in response.data.data) {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.data[key],
                message: response.data.data[key],
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      data,
      translate,
      resend,
      submitButton,
      checkPermission
    };
  },
  methods: {}
});
